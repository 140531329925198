import "styles/pages/page-types.scss";
import React from "react";
import Layout from "components/Layout";
import Seo from "components/Seo";
import SectionTitle from "components/SectionTitle";
import Breadcrumbs from "../components/Breadcrumbs";
import PhotoTile from "../components/PhotoTile";

const ChoiceMenuPage = ({ pageContext }) => {
   const seo = pageContext.seo;
   const page = pageContext.data;
   return (
      <Layout>
         <Seo title={seo.title ? seo.title : pageContext.title} />
         <Breadcrumbs currentLocationLabel={pageContext.title} />
         <section className="types-section">
            <div className="container">
               <SectionTitle title={pageContext.title} />
               <div className="row">
                  {page.pozycje.map((item, index) => (
                     <div className="col-md-6" key={index}>
                        <PhotoTile
                           title={item.title}
                           text={item.desc}
                           color={item.color}
                           thumbnail={item.image?.sourceUrl}
                           link={item.podstrona}
                           thumbnailSize={250}
                        />
                     </div>
                  ))}
               </div>
            </div>
         </section>
      </Layout>
   );
};

export default ChoiceMenuPage;
