import "./photo-tile.scss";
import React from "react";
import { Link } from "gatsby";

const PhotoTile = ({
   title,
   thumbnail,
   link,
   color,
   text,
   fontSize,
   thumbnailSize,
}) => {
   return (
      <Link to={link} className="photo-tile">
         <div
            className="photo-tile__thumbnail"
            style={{ height: `${thumbnailSize} px` }}
         >
            <img src={thumbnail} alt="" className="photo-tile__thumbnail-img" />
         </div>
         <div
            className="photo-tile__text-container"
            style={{ backgroundColor: color }}
         >
            <h3
               className="photo-tile__title"
               style={{ fontSize: `${fontSize ? fontSize : 1.45}rem` }}
            >
               {title}
            </h3>
            {text && <div className="photo-tile__text">{text}</div>}
         </div>
      </Link>
   );
};

export default PhotoTile;
